import React, { useState } from "react";

import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import { emailValidator, requiredValidator } from "../validators";

// import { AppContext } from "../AppContext";
import { useStores } from "src/stores";
import { useServices } from "src/services";
import { Input } from "src/components/form/Input";
import cpHeaderLogo from "../assets/images/CarePenguin_header_logo.png";

const ForgotPassword = () => {
  // const { ...formValues } = React.useContext(AppContext);
  const localizationService = useLocalization();
  const history = useHistory();

  const [forgotPassError, setForgotPassError] = useState<string>();
  const { ui } = useStores();
  const { carePenguin } = useServices();

  // useEffect(() => {}, []);

  const onSubmit = React.useCallback(
    async (dataItem) => {
      if (
        dataItem.password !== dataItem.confirm_password ||
        dataItem.password.length < 5
      ) {
        setForgotPassError(
          "Passwords must match and be at least 5 characters long"
        );
        return;
      }
      const call: any = await carePenguin.sendResetPasswordCode(dataItem.email);
      if (!call) {
        console.log("[forgotPassword]", "forgotPassword Error");
        setForgotPassError(
          "Reset attempt failed, be sure to enter the correct email address or contact support for help."
        );
        return;
      }
      // set new password and email to store for next screen
      ui.setResetPasswordEmail(dataItem.email);
      ui.setResetPasswordPass(dataItem.password);
      history.push("/2fa_forgotPassword");
    },
    [carePenguin, ui, history]
  );

  return (
    <div id="signup" className="forgot-pass-page">
      <div className="card-container" style={{ maxWidth: 700 }}>
        <div className="card-component">
          <div className="header-img-container">
            <img
              src={cpHeaderLogo}
              alt="carepenguin header logo"
              className="cp-header-img"
            />
          </div>
          <Button onClick={() => history.push("/login")} className="back-btn">
            <span className="k-icon k-i-arrow-chevron-left"></span>Back to Login
          </Button>
          <p className="reset-header">Reset Password</p>
          <p className="reset-text">
            Enter your email address and new password. Then, a code will be
            texted to you to confirm your password reset.
          </p>
          <Form
            onSubmit={onSubmit}
            // initialValues={{
            //     ...formValues,
            //     email: login.loginEmail,
            // }}
            render={(formRenderProps) => (
              <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                {forgotPassError && <Error>{forgotPassError}</Error>}
                <Field
                  id={"email"}
                  name={"email"}
                  type={"email"}
                  placeholder={"e.g.: peter@gmail.com"}
                  label={localizationService.toLanguageString(
                    "custom.email",
                    "Email"
                  )}
                  validator={emailValidator}
                  required={true}
                  component={Input}
                />
                <Field
                  id={"password"}
                  name={"password"}
                  type={"password"}
                  label={localizationService.toLanguageString(
                    "custom.password",
                    "New Password"
                  )}
                  validator={requiredValidator}
                  component={Input}
                />
                <Field
                  id={"confirm_password"}
                  name={"confirm_password"}
                  type={"password"}
                  label={localizationService.toLanguageString(
                    "custom.confirm_password",
                    "Confirm Password"
                  )}
                  validator={requiredValidator}
                  component={Input}
                />
                <hr />
                <div className={"k-form-buttons"}>
                  <Button
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                    className="reset-pass-btn"
                  >
                    {localizationService.toLanguageString(
                      "custom.resetPassword",
                      "Reset Password"
                    )}
                  </Button>
                </div>
              </FormElement>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
