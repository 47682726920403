import React, { useEffect, useState } from "react";
// import { toJS } from "mobx";
import moment from "moment-timezone";
// import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory } from "react-router-dom";
// import { Form, Field, FormElement } from "@progress/kendo-react-form";
// import { orderBy } from "@progress/kendo-data-query";
// import { Error } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
// import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
// import { Input } from "../components/form/Input";
// import { AppContext } from "../AppContext";
import { useStores } from "../stores";
import carePenguin from "src/services/carePenguin";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLine } from "victory";

const Location = () => {
  // const { languageId, onLanguageChange, onProfileChange, ...formValues } =
  //   React.useContext(AppContext);
  // const { location_id } = useParams<AccountsParams>();
  const history = useHistory();
  const { ui } = useStores();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditingName, setIsEditingName] = useState<boolean>(false);
  const [newSensorName, setNewSensorName] = useState<string>("");
  const [editError, setEditError] = useState<string>("");
  const [editSuccess, setEditSuccess] = useState<string>("");

  useEffect(() => {
    if (!ui.selectedLocation || !ui.selectedLocation.id) {
      history.goBack();
      return;
    }
    getSensorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSensorData = async () => {
    setIsLoading(true);
    const newData: any = await carePenguin.getSensorData(ui.selectedLocation.id);
    const newSensors: Sensor[] = [];
    // TODO: this assumes that the arrays of data are returned in the same order
    // parse data
    // @ts-ignore
    for (let i in newData.result.sensors) {
      // @ts-ignore
      const sensor = newData.result.sensors[i];
      const sensorEventsCall = await carePenguin.getSensorEvents(
        sensor.id,
        ui.selectedLocation.id
      );
      // @ts-ignore
      const sensorPeakEvents = sensorEventsCall.result.events;
      // @ts-ignore
      const sensorData = newData.result.sensorData[i].data;
      // @ts-ignore
      const sensorEvents = newData.result.sensorEvents[i].eventSummaries;

      let eventTotal = 0;

      for (let e of sensorEvents) {
        eventTotal += e.actualEvents;
      }

      newSensors.push({
        ...sensor,
        eventTotal,
        data: sensorData,
        events: sensorEvents,
        peakEvents: sensorPeakEvents,
      });
    }
    ui.setSensorData(newSensors);
    setIsLoading(false);
  };

  const renderSubHeader = (lastEventReceived: string, timezone: string) => {
    if (!lastEventReceived) {
      return <p>Warning: Not receiving sensor data</p>;
    } else if (
      moment(lastEventReceived)
        .tz(timezone)
        .isBefore(moment().tz(timezone).subtract(10, "minutes"))
    ) {
      return <p>Warning: Sensor data is stale</p>;
    }
    return <p>Sensor data is up to date</p>;
  };

  const renderLastReceived = (lastEventReceived: string, timezone: string) => {
    const now = moment().tz(timezone);
    let stamp = "seconds";
    let difference = now.diff(
      moment(lastEventReceived).tz(timezone),
      "seconds"
    );
    if (difference > 60) {
      difference = now.diff(moment(lastEventReceived).tz(timezone), "minutes");
      stamp = "minutes";
    }
    if (!moment(lastEventReceived).isSame(moment().tz(timezone), "hour")) {
      return `at ${moment(lastEventReceived)
        .tz(timezone)
        .format("h:mm a")} on ${moment(lastEventReceived)
        .tz(timezone)
        .format("MMM Do YYYY")}`;
    }
    return `${difference} ${stamp} ago`;
  };

  const renderAnalysisGraph = (sensor: Sensor) => {
    const spikes = sensor.peakEvents
      ? sensor.peakEvents.map((spike) => {
          return {
            y: 130,
            x: moment(spike.eventDetail.endedAt).valueOf(),
          };
        })
      : [];

    const renderGraph = () => {
      const data = sensor.data;
      const hoursToGet: number[] = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24,
      ];
      const timestampMap = hoursToGet.map((o) =>
        moment()
          .tz(ui.selectedLocation.timezone)
          .startOf("day")
          .add(o, "hour")
          .startOf("hour")
          .valueOf()
      );
      return (
        <div>
          <VictoryChart height={300}>
            <VictoryAxis
              dependentAxis
              tickValues={[
                0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130,
              ]}
              style={{
                ticks: {
                  strokeWidth: 1,
                  size: -5,
                  stroke: "black",
                },
                tickLabels: {
                  padding: 20,
                  fontFamily: "Rubik-Medium",
                },
              }}
            />
            <VictoryAxis
              scale={{ x: "time" }}
              tickValues={timestampMap}
              domain={{ x: [timestampMap[0], timestampMap[24]] }}
              tickFormat={(timestamp): string => {
                const hour = moment(timestamp)
                  .tz(ui.selectedLocation.timezone)
                  .hour();
                return hour % 6
                  ? ""
                  : moment(timestamp)
                      .tz(ui.selectedLocation.timezone)
                      .format("ha");
              }}
              tickCount={25}
              style={{
                ticks: {
                  stroke: "black",
                  strokeWidth: ({ index }) => {
                    // @ts-ignore
                    return index % 3 ? 0.5 : 2;
                  },
                  size: ({ index }) => {
                    if (index === 0) {
                      return 0;
                    }
                    // @ts-ignore
                    return index % 3 ? -5 : -10;
                  },
                },
                tickLabels: {
                  fontSize: 15,
                  fontFamily: "Rubik-Medium",
                  padding: ({ index }) => {
                    if (index === 0) {
                      return 10;
                    }
                    // @ts-ignore
                    return index % 3 ? 15 : 20;
                  },
                },
              }}
            />
            <VictoryLine
              data={data}
              interpolation="linear"
              x={(data: SensorData) => new Date(data.epochMillis)}
              style={{ data: { stroke: "#215668" } }}
              y={(data: SensorData) => data.temp}
            />
            <VictoryBar
              data={spikes}
              style={{
                data: {
                  fill: "#B3D183",
                  width: 1,
                },
              }}
            />
          </VictoryChart>
        </div>
      );
    };
    return renderGraph();
  };

  const submitSensorNameChange = async (sensorID: number, name: string) => {
    if (!name.length) {
      setEditError("Must enter a valid sensor name.");
      setTimeout(() => {
        setEditError("");
      }, 5000);
      return;
    }
    const call: any = await carePenguin.doEditSensorName(sensorID, name);
    if (call.result.errors) {
      setEditError(call.result.errors.join("\n"));
      setTimeout(() => {
        setEditError("");
      }, 5000);
      return;
    }
    if (call.result) {
      // TODO: figure out how to refetch sensor info to reflect name change success instead of current solution below
      setIsEditingName(false);
      setEditSuccess(
        "Success! Changes may take a minute to apply. Try refreshing if needed."
      );
    }
  };

  const renderSensorCard = (sensors: any) => {
    if (!sensors.length) {
      return <p>No sensors are set up at this location.</p>;
    }
    return sensors.map((sensor: any) => {
      return (
        <div
          key={sensor.id}
          style={{
            border: "2px solid black",
            width: "45%",
            padding: 5,
            marginRight: 10,
            marginBottom: 10,
          }}
        >
          <div>
            <div className="sensor-card-header">
              <span
                className="k-icon k-i-circle"
                style={
                  moment(sensor.lastEventReceived)
                    .tz(sensor.timezone)
                    .isBefore(
                      moment().tz(sensor.timezone).subtract(10, "minutes")
                    )
                    ? { color: "#FF6358" }
                    : { color: "#3f72b4" }
                }
              ></span>
              {isEditingName ? (
                <input
                  placeholder={sensor.name}
                  className="sensor-name"
                  value={newSensorName}
                  onChange={(e: any) => setNewSensorName(e.target.value)}
                />
              ) : (
                <h3 className="sensor-name">{sensor.name} (ID: {sensor.id})</h3>
              )}
              {isEditingName ? (
                <div>
                  <button
                    className="k-primary k-button k-grid-edit-command"
                    onClick={() =>
                      submitSensorNameChange(sensor.id, newSensorName)
                    }
                    style={{ marginLeft: 15 }}
                  >
                    Save
                  </button>
                  <button
                    className="k-primary k-button k-grid-edit-command"
                    onClick={() => setIsEditingName(false)}
                    style={{ marginLeft: 15 }}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  className="k-primary k-button k-grid-edit-command"
                  onClick={() => setIsEditingName(true)}
                  style={{ marginLeft: 15 }}
                >
                  Edit
                </button>
              )}
            </div>
          </div>
          <div>
            <div>
              <p className="graph-text">
                {renderSubHeader(
                  sensor.lastEventReceived,
                  ui.selectedLocation.timezone
                )}
              </p>
              {!sensor.firstWaterUsageToday ? null : (
                <p className="graph-text">
                  • First detected water use at{" "}
                  {moment(sensor.firstWaterUsageToday)
                    .tz(ui.selectedLocation.timezone)
                    .format("h:mm a")}
                  .
                </p>
              )}
              {sensor.lastWaterUsageToday ? (
                <p className="graph-text">
                  • Last detected water use at{" "}
                  {moment(sensor.lastWaterUsageToday)
                    .tz(ui.selectedLocation.timezone)
                    .format("h:mm a")}
                  .
                </p>
              ) : null}
              <p className="graph-text">
                • {sensor.usagesToday ? sensor.usagesToday : "0"} water use
                event{sensor.usagesToday === 1 ? null : "s"} detected today.
              </p>
              {sensor.lastEventReceived ? (
                <p className="graph-text">
                  • Last received data{" "}
                  {renderLastReceived(
                    sensor.lastEventReceived,
                    ui.selectedLocation.timezone
                  )}
                  .
                </p>
              ) : null}
              <p className="graph-text">
                • Sensor Timezone is ({ui.selectedLocation.timezone}).
              </p>
            </div>
          </div>
          {renderAnalysisGraph(
            // @ts-ignore
            ui.sensorData.find((uiSensor) => uiSensor.name === sensor.name)
          )}
        </div>
      );
    });
  };

  if (!ui.selectedLocation.state) {
    return (
      <div id="Profile" className="profile-page main-content">
        <div className="card-container">
          <Button
            onClick={() => {
              history.push(`/`);
            }}
          >
            Click here to select a location from the accounts list
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div id="Profile" className="profile-page main-content">
      <Button onClick={() => history.push("/")} className="back-btn">
        <span className="k-icon k-i-arrow-chevron-left"></span>Back to Search
      </Button>
      <Button
        onClick={() => history.push(`/user/${ui.lastUser}`)}
        className="back-btn"
        disabled={!ui.lastUser}
      >
        <span className="k-icon k-i-arrow-chevron-left"></span>Back to User:{" "}
        {ui.lastUser}
      </Button>
      <Button
        onClick={() => history.push(`/account/${ui.lastAccount}`)}
        className="back-btn"
        disabled={!ui.lastAccount}
      >
        <span className="k-icon k-i-arrow-chevron-left"></span>Back to Account:{" "}
        {ui.lastAccount}
      </Button>
      <div className="card-container">
        <h3 className="account-title">Location: {ui.selectedLocation.name}</h3>
        <p>ID: {ui.selectedLocation.id}</p>
        <p>Timezone: {ui.selectedLocation.timezone}</p>
        <p>
          Usages Today:{" "}
          {ui.selectedLocation.sensors.length
            ? ui.selectedLocation.usagesToday
            : "No Sensors"}
        </p>
        <p>
          {`State: ${ui.selectedLocation.state.state} (Entered At: ${ui.selectedLocation.state.enteredAt})`}
        </p>
        {/* <button
          className="k-primary k-button k-grid-edit-command"
          // onClick={() => enterEdit(dataItem)}
          disabled
        >
          Edit
        </button> */}
      </div>
      <div className="card-container">
        <h3 className="account-title">
          Sensors at Location: {ui.selectedLocation.name}
        </h3>
        <p style={{ color: "red" }}>{editError}</p>
        <p style={{ color: "green" }}>{editSuccess}</p>
        <div className="card-component">
          {isLoading ? (
            <p>loading...</p>
          ) : (
            renderSensorCard(ui.selectedLocation.sensors)
          )}
        </div>
      </div>
    </div>
  );
};

export default Location;
