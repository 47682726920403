import React, { useEffect, useState } from "react";

import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import { requiredValidator } from "../validators";

import { AppContext } from "./../AppContext";
import { useStores } from "src/stores";
import { useServices } from "src/services";
import { Input } from "src/components/form/Input";
import cpHeaderLogo from "../assets/images/CarePenguin_header_logo.png";

const LoginCodeScreen = () => {
  const { ...formValues } = React.useContext(AppContext);
  const localizationService = useLocalization();
  const history = useHistory();

  const [loginError, setLoginError] = useState<string>();
  const { login } = useStores();
  const { carePenguin } = useServices();

  let location = useLocation<Location>();

  let { from } = (location.state as any) || { from: { pathname: "/" } };

  useEffect(() => {
    if (login.loginObject) {
      console.log("[LoginScreen]", "I think we are already logged in");
      carePenguin.getAccount().then(() => {
        history.replace("/");
      });
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = React.useCallback(
    async (dataItem) => {
      const call: any = await carePenguin.doVerify(
        login.loginEmail,
        dataItem.code
      );
      if (call.result.errors) {
        console.log("[LoginCodeScreen]", "Login Error");
        setLoginError(call.result.errors.join("\n"));
      }
      if (call.result.user) {
        console.log("[LoginCodeScreen]", "successful 2fa");
        login.setLoginObject(call.result as Login);
        await carePenguin.getAccount();
        history.replace("/");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [login, from, history, carePenguin]
  );

  return (
    <div id="Login" className="login-page">
      <div className="card-container" style={{ maxWidth: 700 }}>
        <div className="card-component">
          <div className="header-img-container">
            <img
              src={cpHeaderLogo}
              alt="carepenguin header logo"
              className="cp-header-img"
            />
          </div>
          <Button
            onClick={() => history.push("/login")}
            className="back-btn"
            style={{ marginBottom: "20px" }}
            type="button"
          >
            <span className="k-icon k-i-arrow-chevron-left"></span>Back to Login
          </Button>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              ...formValues,
            }}
            render={(formRenderProps) => (
              <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                {loginError && <Error>{loginError}</Error>}
                <Field
                  id={"code"}
                  name={"code"}
                  label={localizationService.toLanguageString(
                    "custom.verificationCode",
                    "Verification Code"
                  )}
                  validator={requiredValidator}
                  component={Input}
                />
                <hr />
                <div className={"k-form-buttons"}>
                  <Button
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                    style={{ fontFamily: "Inter-Medium" }}
                  >
                    {localizationService.toLanguageString(
                      "custom.submit",
                      "Submit"
                    )}
                  </Button>
                </div>
              </FormElement>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginCodeScreen;
