import React, { MouseEventHandler, useEffect, useState } from "react";

import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  LocalizationService,
  useLocalization,
} from "@progress/kendo-react-intl";
import {
  Drawer,
  DrawerContent,
  DrawerItemProps,
  DrawerSelectEvent,
} from "@progress/kendo-react-layout";

import Header from "./Header";
import { useServices } from "src/services";
import { observer } from "mobx-react-lite";

const DrawerRouterContainer: React.FC<RouteComponentProps> = (props) => {
  const { history, location, children } = props;
  const { carePenguin } = useServices();

  const items: DrawerItemProps[] = [
    { name: "Search", icon: "k-i-search", selected: true, route: "/" },
    {
      name: "Browse Accounts",
      icon: "k-i-rows",
      selected: true,
      route: "/browse",
    },
    // { name: 'myAccount', icon: 'k-i-user', route: '/profile' },

    // {
    //   name: "myLocations",
    //   icon: "k-i-grid",
    //   selected: true,
    //   route: "/locations",
    // },
    // { name: 'planning', icon: 'k-i-calendar', route: '/planning' },
    { separator: true },
    // { name: 'help', icon: 'k-i-help', route: '/dashboard' },
    // { name: 'contact', icon: 'k-i-email', route: '/info' },
    { separator: true },
    {
      name: "logout",
      icon: "k-i-logout",
      onClick: async () => {
        console.log("log out");
        await carePenguin.doLogout();
        history.replace("/");
      },
    },
  ];

  const [isSmallerScreen, setIsSmallerScreen] = useState<boolean>(
    window.innerWidth < 768
  );
  const [expanded, setExpanded] = useState<boolean>(!isSmallerScreen);

  const resizeWindow = () => {
    setIsSmallerScreen(window.innerWidth < 768);
  };

  const handleClick: MouseEventHandler = () => {
    setExpanded(!expanded);
  };

  const handleSelect = (e: DrawerSelectEvent) => {
    setExpanded(!isSmallerScreen);
    history.push(e.itemTarget.props.route);
  };

  const getSelectedItem = (pathName: string) => {
    let currentPath = items.find((item) => item.route === pathName);
    if (currentPath?.name) {
      return currentPath.name;
    }
  };

  // componentDidUpdate() {
  //     try {
  //         const parent = window.parent;
  //         if(parent) {
  //             parent.postMessage({ url: location.pathname, demo: true }, "*")
  //         }
  //     } catch(err) {
  //         console.warn('Cannot access iframe')
  //     }
  // }

  useEffect(() => {
    window.addEventListener("resize", resizeWindow, false);
    resizeWindow();
    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  });

  let selected = getSelectedItem(location.pathname);
  const localizationService: LocalizationService = useLocalization();

  return (
    <React.Fragment>
      <Header
        onButtonClick={handleClick}
        page={localizationService.toLanguageString(
          `custom.${selected}`,
          "CarePenguin"
        )}
      />
      <Drawer
        expanded={expanded}
        animation={{ duration: 100 }}
        items={items.map((item) => ({
          ...item,
          text: localizationService.toLanguageString(
            `custom.${item.name}`,
            item.name
          ),
          selected: item.name === selected,
          style:
            item.name === selected
              ? { fontFamily: "Inter-Medium", color: "white" }
              : { fontFamily: "Inter-Medium", color: "#12384C" },
        }))}
        position="start"
        mode={isSmallerScreen ? "overlay" : "push"}
        mini={isSmallerScreen ? false : true}
        onOverlayClick={handleClick}
        onSelect={handleSelect}
      >
        <DrawerContent style={{ height: 1066 }}>{children}</DrawerContent>
      </Drawer>
    </React.Fragment>
  );
};

export default withRouter(observer(DrawerRouterContainer));
