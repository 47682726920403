import React, { useEffect, useState } from "react";

import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import {
  emailValidator,
  requiredValidator,
  phoneValidator,
} from "../validators";

// import { AppContext } from "./../AppContext";
import { useStores } from "src/stores";
import { useServices } from "src/services";
import { Input } from "src/components/form/Input";
import cpHeaderLogo from "../assets/images/CarePenguin_header_logo.png";

const SignUp = () => {
  // const { ...formValues } = React.useContext(AppContext);
  const localizationService = useLocalization();
  const history = useHistory();

  const [signUpError, setSignUpError] = useState<string>();
  const { signUp } = useStores();
  const { carePenguin } = useServices();

  let location = useLocation<Location>();

  let { from } = (location.state as any) || { from: { pathname: "/" } };

  useEffect(() => {}, []);

  const onSubmit = React.useCallback(
    async (dataItem) => {
      if (
        dataItem.password !== dataItem.confirm_password ||
        dataItem.password.length < 5
      ) {
        setSignUpError("Passwords must match and be at least 5 characters long");
        return;
      }
      signUp.setSignUpEmail(dataItem.email);
      signUp.setSignUpName(dataItem.name);
      signUp.setSignUpPassword(dataItem.password);
      signUp.setSignUpPhone(dataItem.phone);

      const call: any = await carePenguin.signUp(
        dataItem.email,
        dataItem.password,
        dataItem.name,
        dataItem.phone
      );
      if (call.result.errors) {
        console.log("[SignUp]", "signup Error");
        setSignUpError(call.result.errors.join("\n"));
        return;
      }
      if (call.result.user.verified) {
        console.log("[SignUp]", "already verified, no 2fa required");
        history.replace(from);
      }
      if (!call.result.user.verified) {
        console.log("[SignUp]", "code sent to email");
        history.replace("/2fa_signUp", { ...location.state, ...call.result });
      }
    },
    [signUp, from, history, carePenguin, location.state]
  );

  return (
    <div id="signup" className="signup-page">
      <div className="card-container" style={{ maxWidth: 700 }}>
        <div className="card-component">
          <div className="header-img-container">
            <img
              src={cpHeaderLogo}
              alt="carepenguin header logo"
              className="cp-header-img"
            />
          </div>
          <Button onClick={() => history.push("/login")} className="back-btn">
            <span className="k-icon k-i-arrow-chevron-left"></span>Back to Login
          </Button>
          <p className="reset-header">Sign Up</p>
          <p className="reset-text">
            Fill out and submit the form below. Then, a code will be emailed to
            you that you will enter in the next screen to verify your new
            account.
          </p>
          <Form
            onSubmit={onSubmit}
            // initialValues={{
            //     ...formValues,
            //     email: login.loginEmail,
            // }}
            render={(formRenderProps) => (
              <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                {signUpError && <Error>{signUpError}</Error>}
                <Field
                  id={"name"}
                  name={"name"}
                  type={"name"}
                  placeholder={"First Last"}
                  label={localizationService.toLanguageString(
                    "custom.name",
                    "Name"
                  )}
                  validator={requiredValidator}
                  required={true}
                  component={Input}
                />
                <Field
                  id={"email"}
                  name={"email"}
                  type={"email"}
                  placeholder={"e.g.: peter@gmail.com"}
                  label={localizationService.toLanguageString(
                    "custom.email",
                    "Email"
                  )}
                  validator={emailValidator}
                  required={true}
                  component={Input}
                />
                <Field
                  id={"phone"}
                  name={"phone"}
                  type={"phone"}
                  placeholder={"e.g.: 303-555-4444"}
                  label={localizationService.toLanguageString(
                    "custom.phone",
                    "Phone"
                  )}
                  validator={phoneValidator}
                  required={true}
                  component={Input}
                />
                <Field
                  id={"password"}
                  name={"password"}
                  type={"password"}
                  label={localizationService.toLanguageString(
                    "custom.password",
                    "Password"
                  )}
                  validator={requiredValidator}
                  component={Input}
                />
                <Field
                  id={"confirm_password"}
                  name={"confirm_password"}
                  type={"password"}
                  label={localizationService.toLanguageString(
                    "custom.confirm_password",
                    "Confirm Password"
                  )}
                  validator={requiredValidator}
                  component={Input}
                />
                <hr />
                <div className={"k-form-buttons"}>
                  <Button
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                    className="signup-btn"
                  >
                    {localizationService.toLanguageString(
                      "custom.signUp",
                      "Sign Up"
                    )}
                  </Button>
                </div>
              </FormElement>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
