import React, { useEffect, useState } from "react";
// import { toJS } from "mobx";
import { useHistory } from "react-router-dom";
import { orderBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
// import { Error } from "@progress/kendo-react-labels";
// import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import carePenguin from "src/services/carePenguin";
// import { Input } from "@progress/kendo-react-inputs";

// import { Loader } from "@progress/kendo-react-indicators";

const Search = () => {
  // const { languageId, onLanguageChange, onProfileChange } =
  //   React.useContext(AppContext);
  // interface PageState {
  //   skip: number;
  //   take: number;
  // }

  // const initialDataState: PageState = { skip: 0, take: 10 };
  const history = useHistory();
  const [editError, setEditError] = useState<string>("");
  const [sort, setSort] = useState<Array<any>>([
    {
      dir: "asc",
      field: "accountId",
    },
  ]);
  const [accountsResult, setAccountsResult] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [page, setPage] = React.useState<PageState>(initialDataState);
  const [to, setTo] = useState<number>(50);
  const [from, setFrom] = useState<number>(0);

  const getAccounts = async () => {
    setIsLoading(true);
    const call: any = await carePenguin.getAccountsForBrowse(from, to);

    if (call.result.errors) {
      setEditError(call.result.errors.join("\n"));
      setIsLoading(false);
      return;
    }
    if (call.result) {
      setAccountsResult(call.result);
      setIsLoading(false);
    }
  };

  const doNextPage = () => {
    // setPage(event.page);
    setTo(to + 50);
    setFrom(from + 50);
  };
  const doPrevPage = () => {
    // setPage(event.page);
    setTo(to - 50);
    setFrom(from - 50);
  };

  useEffect(() => {
    getAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [to, from]);

  const commandCell = (dataItem: any) => {
    return (
      <td>
        <button
          className="k-primary k-button k-grid-edit-command"
          onClick={() => {
            const accountID = dataItem.dataItem.accountId;
            history.push(`/account/${accountID}`);
          }}
        >
          View Account
        </button>
      </td>
    );
  };

  const locationStateCell = (dataItem: any) => {
    const colors: any = {
      CRITICAL: "red",
      NORMAL: "green",
      WARNING: "orange",
      SUPPRESSED: "#620436",
      PAUSED: "#620436",
      EMPTY: null,
    };
    let worstState;
    let account = dataItem.dataItem;

    if (account.locationStates.length === 1) {
      worstState = account.locationStates[0].state;
    } else if (!account.locationStates.length) {
      worstState = "EMPTY";
    } else if (account.locationStates.length > 1) {
      const statuses = account.locationStates.map((location: any) => {
        return location.state;
      });
      switch (true) {
        case statuses.includes("CRITICAL"):
          worstState = "CRITICAL";
          break;
        case statuses.includes("WARNING"):
          worstState = "WARNING";
          break;
        case statuses.includes("SUPPRESSED"):
          worstState = "SUPPRESSED";
          break;
        case statuses.includes("PAUSED"):
          worstState = "PAUSED";
          break;
        case statuses.includes("NORMAL"):
          worstState = "NORMAL";
          break;
        default:
          worstState = "EMPTY";
      }
    }
    return (
      <td style={{ backgroundColor: colors[worstState] }}>
        <p style={{ color: "white" }}>
          {worstState !== "EMPTY" ? worstState : null}
        </p>
      </td>
    );
  };

  if (isLoading) {
    return (
      <div id="Profile" className="profile-page main-content">
        <div className="card-container">
          <p>Loading accounts...</p>
        </div>
      </div>
    );
  }

  return (
    <div id="Profile" className="profile-page main-content">
      <div className="card-container">
        <h3 className="account-title">Account Browser</h3>
        {!editError ? null : (
          <p style={{ color: "red" }}>Error getting accounts: {editError}</p>
        )}
        <p>
          Click on the column headers to toggle sort. You can also click and
          drag the borders between them to resize whole columns for better
          visibility.
        </p>
        <div className="page_btns_container">
          <Button
            primary={true}
            type={"submit"}
            disabled={!from}
            className="page_buttons"
            onClick={() => doPrevPage()}
            // disabled={!formRenderProps.allowSubmit && !ui.lastQuery}
            style={{ fontFamily: "Inter-Medium" }}
          >
            {/* {localizationService.toLanguageString(
            "custom.search",
            "Search"
          )} */}
            {`< Previous Page`}
          </Button>
          <Button
            primary={true}
            disabled={!accountsResult.length}
            onClick={() => doNextPage()}
            type={"submit"}
            className="page_buttons"
            // disabled={!formRenderProps.allowSubmit && !ui.lastQuery}
            style={{ fontFamily: "Inter-Medium" }}
          >
            {/* {localizationService.toLanguageString(
            "custom.search",
            "Search"
          )} */}
            {`Next Page >`}
          </Button>
        </div>
        <p style={{ fontWeight: 600 }}>
          Showing Account ID's: {from} through {to}
        </p>
        {/* <Form
          onSubmit={onSubmit}
          initialValues={
            {
              ...formValues,
              query: ui.lastQuery,
            }
          }
          render={(formRenderProps) => (
            <FormElement horizontal={true} style={{ maxWidth: 700 }}>
              <Input placeholder={"From"} type={"number"} />
              <Input placeholder={"To"} type={"number"} />
              <hr />
              <div className="save-changes-btn-container">
                <Button
                  primary={true}
                  type={"submit"}
                  className="save-changes-btn"
                  disabled={!formRenderProps.allowSubmit && !ui.lastQuery}
                  style={{ fontFamily: "Inter-Medium" }}
                >
                  {localizationService.toLanguageString(
                      "custom.search",
                      "Search"
                    )}
                  Search
                </Button>
              </div>
            </FormElement>
          )}
        /> */}
        <div className="card-component">
          <Grid
            data={orderBy(accountsResult, sort)}
            // data={orderBy(
            //   accountsResult.slice((page.skip, page.take + page.skip)),
            //   sort
            // )}
            sortable
            sort={sort}
            onSortChange={(e) => setSort(e.sort)}
            resizable
            editField={"inEdit"}
            pageable={false}
            // JUST ADD IN NEXT/PREV PAGE BUTTONS NOT PART OF THE GRID AND HANDLE IT MANUALLY
            // skip={page.skip}
            // take={page.take}
            // pageSize={20}
            // total={accountsResult.length}
            // onPageChange={pageChange}
          >
            <Column field="accountId" title="Account ID" />
            <Column cell={locationStateCell} title="State" />
            <Column field="accountOwnerName" title="Acct. Owner Name" />
            <Column field="accountOwnerUserId" title="Owner ID" />
            <Column field="activeSensorSubscriptions" title="Active Subs" />
            <Column field="accountBillingOverview" title="Billing Status" />
            <Column cell={commandCell} />
          </Grid>
        </div>
        <div className="page_btns_container">
          <Button
            primary={true}
            type={"submit"}
            className="page_buttons"
            onClick={() => doPrevPage()}
            // disabled={!formRenderProps.allowSubmit && !ui.lastQuery}
            style={{ fontFamily: "Inter-Medium" }}
            disabled={!from}
          >
            {/* {localizationService.toLanguageString(
            "custom.search",
            "Search"
          )} */}
            {`< Previous Page`}
          </Button>
          <Button
            primary={true}
            onClick={() => doNextPage()}
            disabled={!accountsResult.length}
            type={"submit"}
            className="page_buttons"
            // disabled={!formRenderProps.allowSubmit && !ui.lastQuery}
            style={{ fontFamily: "Inter-Medium" }}
          >
            {/* {localizationService.toLanguageString(
            "custom.search",
            "Search"
          )} */}
            {`Next Page >`}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Search;
