import React, { useEffect, useState } from "react";
// import { toJS } from "mobx";
// import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory, useParams } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";
// import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { orderBy } from "@progress/kendo-data-query";
// import { Error } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
// import { Input } from "../components/form/Input";
// import { AppContext } from "../AppContext";
import { useStores } from "../stores";
// import { requiredValidator } from "../validators";
import carePenguin from "src/services/carePenguin";
// import { Loader } from "@progress/kendo-react-indicators";
import timezones from "../stores/timezones.json";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const Account = () => {
  // const { languageId, onLanguageChange, onProfileChange, ...formValues } =
  //   React.useContext(AppContext);
  // const localizationService = useLocalization();
  const { account_id } = useParams<AccountsParams>();
  const history = useHistory();
  const { ui } = useStores();
  // const [userName, setUserName] = useState<string>("");
  const [sort, setSort] = useState<Array<any>>([]);
  const [changeMade, toggleChangeMade] = useState(0);
  const [locations, setLocations] = useState<Array<any>>([]);
  const [caregivers, setCaregivers] = useState<Array<any>>([]);
  const [loadingCaregivers, setLoadingCaregivers] = useState<boolean>(true);
  const [account, setAccount] = useState<any>({});
  const [editError, setEditError] = useState<string>("");
  const [showDeleteDialogue, setShowDeleteDialogue] = useState<boolean>(false);
  const [userForDelete, setUserForDelete] = useState<any>({});

  useEffect(() => {
    getLocations();
    getCaregivers();
    ui.setLatestAccountViewed(account_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocations = async () => {
    const call: any = await carePenguin.doAdminGetAccount(parseInt(account_id));
    if (call.result.errors) {
      console.log("[LocationsScreen]", "get locations Error");
      setEditError(call.result.errors.join("\n"));
      return;
    }
    if (call.result) {
      setLocations(call.result.locations);
      setAccount(call.result);
    }
  };

  const getCaregivers = async () => {
    setLoadingCaregivers(true);
    const call: any = await carePenguin.getMembershipData(parseInt(account_id));
    if (call.result.errors) {
      console.log("[LocationsScreen]", "get caregivers Error");
      // TODO set error
      setEditError(call.result.errors.join("\n"));
      return;
    }
    if (call.result) {
      console.log("ACCOUNT cg'ers:", call.result);
      setCaregivers(call.result.members);
      setLoadingCaregivers(false);
    }
  };

  const update = async (value: any) => {
    const { dataItem } = value;
    const { name, id, timezone } = dataItem;
    const call: any = await carePenguin.doEditLocationName(id, name, timezone);
    if (call.result.errors) {
      setEditError(call.result.errors.join("\n"));
      setTimeout(() => {
        setEditError("");
      }, 5000);
      return;
    }
    if (call.result) {
      cancel({
        dataItem,
      });
    }
  };

  const enterEdit = (event: any) => {
    const { dataItem } = event;
    const resultsCopy = locations;
    const foundIndex = locations.findIndex((item) => item.id === dataItem.id);
    resultsCopy[foundIndex].inEdit = true;
    // grid component is not re-rendering with only setLocations, no idea why: hence the weirdness below
    setLocations([]);
    setLocations(resultsCopy);
    toggleChangeMade(changeMade + 1);
  };

  const cancel = (event: any) => {
    const { dataItem } = event;
    const resultsCopy = locations;
    const foundIndex = locations.findIndex((item) => item.id === dataItem.id);
    resultsCopy[foundIndex].inEdit = false;
    // grid component is not re-rendering with only setLocations, no idea why: hence the weirdness below
    setLocations([]);
    setLocations(resultsCopy);
    toggleChangeMade(changeMade + 1);
  };

  const itemChange = (event: any) => {
    const { dataItem } = event;
    const updatedData = locations.map((item) =>
      item.id === dataItem.id ? { ...item, [event.field]: event.value } : item
    );
    setLocations(updatedData);
  };

  const commandCell = (dataItem: any) => {
    const { inEdit } = dataItem.dataItem;
    return inEdit ? (
      <td className="k-command-cell">
        <button
          className="k-button k-grid-save-command"
          onClick={() => update(dataItem)}
        >
          Update
        </button>
        <button
          className="k-button k-grid-cancel-command"
          onClick={() => cancel(dataItem)}
        >
          Cancel
        </button>
      </td>
    ) : (
      <td className="k-command-cell">
        <button
          className="k-primary k-button k-grid-edit-command"
          onClick={() => enterEdit(dataItem)}
        >
          Edit
        </button>
        <button
          className="k-primary k-button k-grid-edit-command"
          onClick={() => {
            const location_id = dataItem.dataItem.id;
            ui.setSelectedLocation(dataItem.dataItem as CPLocation);
            ui.setLastAccount(account.accountId);
            history.push(`/location/${location_id}`);
          }}
        >
          View Location
        </button>
      </td>
    );
  };

  const timezoneCell = (dataItem: any) => {
    const { inEdit, timezone, id } = dataItem.dataItem;
    return inEdit ? (
      <td className="k-command-cell">
        <DropDownList
          onChange={(val: any) => {
            const resultsCopy = locations;
            const foundIndex = locations.findIndex((item) => item.id === id);
            resultsCopy[foundIndex].timezone = val.value;
            setLocations([]);
            setLocations(resultsCopy);
            toggleChangeMade(changeMade + 1);
          }}
          data={timezones}
          defaultValue={timezone}
        />
      </td>
    ) : (
      <td className="k-command-cell">{timezone}</td>
    );
  };

  const renderMemberList = () => {
    const renderRemoveCgAlert = (name: string, id: number) => {
      setShowDeleteDialogue(true);
      setUserForDelete({
        name,
        id,
      });
    };

    return caregivers.map((member: Member) => {
      return (
        <div key={member.email} className="caregiver-card-container">
          <div>
            <p className="caregiver-text">{member.name}</p>
            <p className="caregiver-text">{member.phoneNumber}</p>
          </div>
          <div className="caregiver-actions">
            <Button
              className="remove-cg-btn"
              onClick={() => renderRemoveCgAlert(member.name, member.id)}
              style={
                account.accountOwnerUserId === member.id
                  ? { display: "none" }
                  : {}
              }
            >
              <p className="remove-caregiver-text">remove</p>
            </Button>
          </div>
        </div>
      );
    });
  };

  const removeCaregiver = async () => {
    const call: any = await carePenguin.removeUser(
      parseInt(account_id),
      userForDelete.id
    );
    if (call.result.errors) {
      // TODO set error
      setShowDeleteDialogue(false);
      setEditError(call.result.errors.join("\n"));
      return;
    }
    if (call.result) {
      setEditError("");
      getCaregivers();
      setShowDeleteDialogue(false);
    }
  };

  return (
    <div id="Profile" className="profile-page main-content">
      {showDeleteDialogue && (
        <Dialog
          title={"Confirm Removal"}
          onClose={() => setShowDeleteDialogue(false)}
          closeIcon={false}
        >
          <p
            style={{
              // margin: "25px",
              textAlign: "center",
              fontFamily: "Inter-Medium",
            }}
          >
            Are you sure you want to remove {userForDelete.name} as a Caregiver?
          </p>
          <DialogActionsBar>
            <button
              className="k-button"
              onClick={() => setShowDeleteDialogue(false)}
              style={{ fontFamily: "Inter-Medium" }}
            >
              Cancel
            </button>
            <button
              className="k-button"
              style={{
                backgroundColor: "#ff6358",
                fontFamily: "Inter-Bold",
                color: "white",
              }}
              onClick={() => removeCaregiver()}
            >
              Confirm Removal
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      <Button onClick={() => history.push("/")} className="back-btn">
        <span className="k-icon k-i-arrow-chevron-left"></span>Back to Search
      </Button>
      <Button
        onClick={() => history.push(`/user/${ui.lastUser}`)}
        className="back-btn"
        disabled={!ui.lastUser}
      >
        <span className="k-icon k-i-arrow-chevron-left"></span>Back to User:{" "}
        {ui.lastUser}
      </Button>
      <div className="card-container account-container">
        <div style={{ width: "50%", borderRight: "1px solid grey" }}>
          <h3 className="account-title">Account: {account.account}</h3>
          <p>ID: {account.accountId}</p>
          <p>Account Billing: {account.accountBillingOverview}</p>
          <p>Account Owner: {account.accountOwnerName}</p>
          <p>Account Owner Email: {account.accountOwnerEmail}</p>
          <p>Account Owner ID: {account.accountOwnerUserId}</p>
          <p>
            Account State: {account.state} - {account.stateText}
          </p>
          {/* <button
            className="k-primary k-button k-grid-edit-command"
            // onClick={() => enterEdit(dataItem)}
            disabled
          >
            Edit
          </button> */}
        </div>
        <div style={{ width: "50%" }}>
          <h3 className="account-title" style={{ textAlign: "right" }}>
            {account.account} Caregivers
          </h3>
          <div>
            {loadingCaregivers ? <p>Loading...</p> : renderMemberList()}
          </div>
        </div>
      </div>
      <div className="card-container">
        <h3 className="account-title">{account.account} Locations</h3>
        <p style={{ color: "red" }}>{editError}</p>
        <p>
          Click on the column headers to toggle sort. You can also click and
          drag the borders between them to resize whole columns for better
          visibility.
        </p>
        <div className="card-component">
          <Grid
            data={orderBy(locations, sort)}
            sortable
            sort={sort}
            onSortChange={(e) => setSort(e.sort)}
            resizable
            editField={"inEdit"}
            onItemChange={itemChange}
          >
            <Column field="id" title="Location ID" editable={false} />
            <Column field="name" title="Location Name" />
            <Column
              field="state.state"
              title="Location State"
              editable={false}
            />
            <Column field="timezone" title="Timezone" cell={timezoneCell} />
            <Column cell={commandCell} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Account;
