import React, { useEffect, useState } from "react";
// import { toJS } from "mobx";
import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { orderBy } from "@progress/kendo-data-query";
// import { Error } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Input } from "../components/form/Input";
import { AppContext } from "../AppContext";
import { useStores } from "../stores";
import { requiredValidator } from "../validators";
import carePenguin from "src/services/carePenguin";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
// import { Loader } from "@progress/kendo-react-indicators";

const Search = () => {
  const { languageId, onLanguageChange, onProfileChange, ...formValues } =
    React.useContext(AppContext);
  const localizationService = useLocalization();
  const history = useHistory();
  const { ui } = useStores();
  const [editError, setEditError] = useState<string>("");
  const [sort, setSort] = useState<Array<any>>([]);
  const [changeMade, toggleChangeMade] = useState(0);
  const [showDeleteDialogue, setShowDeleteDialogue] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Array<any>>([]);
  const [userForDelete, setUserForDelete] = useState<any>({});

  const onSubmit = async (dataItem: any) => {
    ui.setLastQuery(dataItem.query);
    const call: any = await carePenguin.doUserSearch(dataItem.query, 500);
    if (call.result.errors) {
      setEditError(call.result.errors.join("\n"));
      return;
    }
    if (call.result) {
      setSearchResults(call.result.users);
    }
  };

  useEffect(() => {
    if (ui.lastQuery) {
      onSubmit({
        query: ui.lastQuery,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const update = async (value: any) => {
    const { dataItem } = value;
    const { name, email, id } = dataItem;
    const call: any = await carePenguin.doEditUser(id, email, name);
    if (call.result.errors) {
      setEditError(call.result.errors.join("\n"));
      setTimeout(() => {
        setEditError("");
      }, 5000);
      return;
    }
    if (call.result) {
      cancel({
        dataItem,
      });
    }
  };

  const enterEdit = (event: any) => {
    const { dataItem } = event;
    const resultsCopy = searchResults;
    const foundIndex = searchResults.findIndex(
      (item) => item.id === dataItem.id
    );
    resultsCopy[foundIndex].inEdit = true;
    // grid component is not re-rendering with only setSearchResults, no idea why: hence the weirdness below
    setSearchResults([]);
    setSearchResults(resultsCopy);
    toggleChangeMade(changeMade + 1);
  };

  const cancel = (event: any) => {
    const { dataItem } = event;
    const resultsCopy = searchResults;
    const foundIndex = searchResults.findIndex(
      (item) => item.id === dataItem.id
    );
    resultsCopy[foundIndex].inEdit = false;
    // grid component is not re-rendering with only setSearchResults, no idea why: hence the weirdness below
    setSearchResults([]);
    setSearchResults(resultsCopy);
    toggleChangeMade(changeMade + 1);
  };

  const itemChange = (event: any) => {
    const { dataItem } = event;
    const updatedData = searchResults.map((item) =>
      item.id === dataItem.id ? { ...item, [event.field]: event.value } : item
    );
    setSearchResults(updatedData);
  };

  const commandCell = (dataItem: any) => {
    const { inEdit } = dataItem.dataItem;
    return inEdit ? (
      <td className="k-command-cell">
        <button
          className="k-button k-grid-save-command"
          onClick={() => update(dataItem)}
        >
          Update
        </button>
        <button
          className="k-button k-grid-cancel-command"
          onClick={() => cancel(dataItem)}
        >
          Cancel
        </button>
      </td>
    ) : (
      <td className="k-command-cell">
        <button
          className="k-primary k-button k-grid-edit-command"
          onClick={() => enterEdit(dataItem)}
        >
          Edit
        </button>
        <button
          className="k-primary k-button k-grid-edit-command"
          onClick={() => {
            const userID = dataItem.dataItem.id;
            history.push(`/user/${userID}`);
          }}
        >
          View User
        </button>
      </td>
    );
  };

  const deleteUserCell = (dataItem: any) => {
    return (
      <td className="k-command-cell" width={50}>
        <button
          className="k-primary k-button k-grid-edit-command"
          onClick={() => {
            setUserForDelete({
              name: dataItem.dataItem.name,
              phone: dataItem.dataItem.phoneNumber,
            });
            setShowDeleteDialogue(true);
          }}
          style={{ width: "100%" }}
        >
          Delete
        </button>
      </td>
    );
  };

  const deleteUser = async (phoneNumber: string) => {
    const call: any = await carePenguin.purgeUser(phoneNumber);
    if (call.result.errors) {
      console.log("[UserScreen]", "purge user Error", call.result.errors);
      // TODO set error
      setShowDeleteDialogue(false);
      setEditError(call.result.errors.join("\n"));
      return;
    }
    if (call.result) {
      setEditError("");
      onSubmit({
        query: ui.lastQuery,
      });
      setShowDeleteDialogue(false);
    }
  };

  return (
    <div id="Profile" className="profile-page main-content">
      {showDeleteDialogue && (
        <Dialog
          title={"Confirm Deletion"}
          onClose={() => setShowDeleteDialogue(false)}
          closeIcon={false}
        >
          <p
            style={{
              // margin: "25px",
              textAlign: "center",
              fontFamily: "Inter-Medium",
            }}
          >
            Are you sure you want to delete user: {userForDelete.name}?
          </p>
          <DialogActionsBar>
            <button
              className="k-button"
              onClick={() => setShowDeleteDialogue(false)}
              style={{ fontFamily: "Inter-Medium" }}
            >
              Cancel
            </button>
            <button
              className="k-button"
              style={{
                backgroundColor: "#ff6358",
                fontFamily: "Inter-Bold",
                color: "white",
              }}
              onClick={() => deleteUser(userForDelete.phone)}
            >
              Confirm Delete
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      <div className="card-container">
        <h3 className="account-title">User Search</h3>
        <p style={{ color: "red" }}>{editError}</p>
        <div className="card-component">
          <Form
            onSubmit={onSubmit}
            initialValues={{
              ...formValues,
              query: ui.lastQuery,
            }}
            render={(formRenderProps) => (
              <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                <Field
                  id={"query"}
                  name={"query"}
                  placeholder={"e.g.: someone@theiremail.com"}
                  label={localizationService.toLanguageString(
                    "custom.query",
                    "Query"
                  )}
                  validator={requiredValidator}
                  component={Input}
                />
                <hr />
                <div className="save-changes-btn-container">
                  <Button
                    primary={true}
                    type={"submit"}
                    className="save-changes-btn"
                    disabled={!formRenderProps.allowSubmit && !ui.lastQuery}
                    style={{ fontFamily: "Inter-Medium" }}
                  >
                    {/* {localizationService.toLanguageString(
                      "custom.search",
                      "Search"
                    )} */}
                    Search
                  </Button>
                </div>
              </FormElement>
            )}
          />
        </div>
      </div>
      <div className="card-container">
        <h3 className="account-title">User Results</h3>
        <p>
          Click on the column headers to toggle sort. You can also click and
          drag the borders between them to resize whole columns for better
          visibility.
        </p>
        <div className="card-component">
          <Grid
            data={orderBy(searchResults, sort)}
            sortable
            sort={sort}
            onSortChange={(e) => setSort(e.sort)}
            resizable
            editField={"inEdit"}
            onItemChange={itemChange}
          >
            <Column field="id" title="User ID" editable={false} />
            <Column field="email" title="Email" />
            <Column field="name" title="Name" />
            <Column field="phoneNumber" title="Phone" editable={false} />
            {/* <Column field="verified" title="Verified" /> */}
            <Column cell={commandCell} />
            <Column cell={deleteUserCell} width={70} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Search;
