import { observable, action, makeObservable } from 'mobx';
import { persist } from 'mobx-persist';

import { HydratedStore } from 'src/utils/classes';

class LoginStore extends HydratedStore {
  constructor() {
    super('LoginStore'); // Storage ID

    makeObservable(this);
  }

  @persist @observable loginEmail: string = '';
  @observable loginPassword: string = '';
  @observable loginPhone: string = '';
  @observable loginCode: string = '';
  @persist('object') @observable loginObject?: Login;
  @persist @observable selectedAccountID: number = 0;

  @action setLoginEmail = (value: string) => this.loginEmail = value;
  @action setLoginPassword = (value: string) => this.loginPassword = value;
  @action setLoginPhone = (value: string) => this.loginPhone = value;
  @action setLoginCode = (value: string) => this.loginCode = value;
  @action setLoginObject = (value?: Login) => this.loginObject = value;
  @action setSelectedAccountID = (value: number) => this.selectedAccountID = value;
  @action doLogout = () => {
    this.selectedAccountID = 0;
    this.loginObject = undefined;
    this.loginPassword = '';
    this.loginCode = '';
  };

  @observable encodedLoginInfo: string = '';
  @action setEncodedLoginInfo = (value: string) => this.encodedLoginInfo = value;
}

export default new LoginStore();
