import { stores } from 'src/stores';

const baseURL = 'https://api.carepenguin.com/';

const doFetch = async (url: RequestInfo, options: RequestInit, skipResults: boolean = false, isPatch: boolean = false):Promise<CPFetchResult> => {
  // console.log('[carePenguin]', 'doFetch', url);
  // add standard header stuff
  options.credentials = 'include';
  options.headers = {
    'Content-Type': !isPatch ? 'application/json' : 'application/json-patch+json',
    'cp-app-version': `web:${stores.ui.appVersion}`,
    // 'Authorization': stores.login.encodedLoginInfo,
    ...options.headers,
  };

  const response = await window.fetch(url, options);
  if (skipResults) {
    let result = {};
    stores.ui.setIsGettingAccount(false);
    return {response, result};
  }
  let result:object;
  try {
    result = await response.json();
  } catch (e:unknown) {
    console.error(`[doFetch]`, e);
    result = {};
  }

  // console.log(`response ${url}`, {
  //   status: response.status,
  //   statusText: response.statusText,
  //   data: result,
  //   headers: response.headers,
  // });
  stores.ui.setIsGettingAccount(false);
  return {response, result};
}

class CarePenguinService implements IService {
  init = async () => {
    console.log(`[CarePenguinAPI] init`);
    this.getAccount();
    setInterval(this.getAccount, 30000, );
    // if (!stores.login.encodedLoginInfo) {
    //   this.doLogout();
    // }
  }

  getAccount = async (newAccountID?:number):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] getAccount`, {newAccountID});
    if (stores.login.loginObject && stores.login.loginObject.accounts && !stores.ui.isLoggingOut) {
      if (newAccountID) {
        await stores.login.setSelectedAccountID(newAccountID);
      } else if (!stores.login.selectedAccountID) {
        let ownerAccount: LoginAccount;
        let [selectedAccount] = stores.login.loginObject.accounts;
      
        for (const account of stores.login.loginObject.accounts) {
          if (account.id === stores.login.selectedAccountID) {
            console.log(`[CarePenguinAPI] getAccount`, 'found selected account');
            selectedAccount = account;
          }
          if (account.accountOwnerUserId === stores.login.loginObject.user?.id) {
            console.log(`[CarePenguinAPI] getAccount`, 'found owner account');
            ownerAccount = account;
          }
        }
  
        if (!stores.login.selectedAccountID && ownerAccount!) {
          console.log(`[CarePenguinAPI] getAccount`, 'defaulting to owner account');
          stores.login.setSelectedAccountID(ownerAccount.id);
        } else if (stores.login.selectedAccountID !== selectedAccount.id) {
          console.log(`[CarePenguinAPI] getAccount`, 'defaulting to selected account');
          stores.login.setSelectedAccountID(selectedAccount.id);
        }
      }

      try {
        // console.log(`[CarePenguinAPI] getAccount`, 'API call', stores.login.selectedAccountID);
        stores.ui.setIsGettingAccount(true);

        const requestOptions: RequestInit = {
          method: 'GET',
        };

        const ret = await doFetch(`${baseURL}account/${stores.login.selectedAccountID}`, requestOptions);
        stores.ui.setIsGettingAccount(false);

        if (ret.response.status === 403) {
          console.error('getAccount', 'User not logged in');
          stores.ui.setAccount(undefined);
          stores.login.doLogout();
          stores.ui.setIsGettingAccount(false);
          // services.nav.setLoginRoot();
        }

        const thisAccount = ret.result as Account;

        if (thisAccount.accountId) {
          stores.ui.setAccount(thisAccount);
          let isPremiumAccount = thisAccount.accountBillingOverview === 'PAID';
          // TODO: Remove this check once accounts are updated (accountBillingOverview === 'CURRENT' has been deprecated according to Nick but our test account is still showing as 'CURRENT')
          if (!['FREE', 'PAID'].includes(thisAccount.accountBillingOverview || '')) {
            isPremiumAccount = true;
          }
          // TODO: REMOVE THIS HARD CODE FOR SHAN'S ACCOUNT TO BASIC PLAN FOR TESTING
          if (thisAccount.accountId === 23) {
            isPremiumAccount = false;
          }
          // console.log('IS PREMIUM', isPremiumAccount, thisAccount );
          
          stores.ui.setIsPremiumAccount(isPremiumAccount);
        } else {
          console.error('getAccount', ret);
          stores.login.doLogout();
        }
        stores.ui.setIsGettingAccount(false);
        return ret;
      } catch (e) {
        // handle error
        console.error('getAccount', e);
        stores.ui.setIsGettingAccount(false);
        // services.nav.dismissAllOverlays();
        console.error(`[CarePenguinAPI] getAccount`, e);
        return e;
      }
    }
    stores.ui.setIsGettingAccount(false);
    return;
  }

  doAdminGetAccount = async (accountId: number) => {
    try {
      // stores.ui.setIsLoggingIn(true);
      const requestOptions: RequestInit = {
        method: 'GET',
      };
  
      const ret = await doFetch(`${baseURL}account/${accountId}`, requestOptions);
      // setTimeout(() => {
      //   stores.ui.setIsLoggingIn(false);
      //   // services.nav.dismissAllOverlays();
      // }, 1000);
      // console.log('doAdminGetAccount OUTPUT', ret);
      return ret;
    } catch (e) {
      // handle error
      console.error('doAdminGetAccount error', e);
      // stores.ui.setIsLoggingIn(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] doAdminGetAccount`, e);
      return e;
    }

  }

  doUserSearch = async (query: string, limit: number = 20) => {
      try {
        // stores.ui.setIsLoggingIn(true);
        const requestOptions: RequestInit = {
          method: 'POST',
          body: JSON.stringify({any: query, limit}),
        };
  
        const ret = await doFetch(`${baseURL}util/users/search`, requestOptions);
        // setTimeout(() => {
        //   stores.ui.setIsLoggingIn(false);
        //   // services.nav.dismissAllOverlays();
        // }, 1000);
        console.log('doSearch OUTPUT', ret);
        // if (ret.response.status === 401) {
        //   return this.doLogout();
        // }
        return ret;
      } catch (e) {
        // handle error
        console.error('doSearch error', e);
        // stores.ui.setIsLoggingIn(false);
        // services.nav.dismissAllOverlays();
        console.error(`[CarePenguinAPI] doSearch`, e);
        return e;
      }
  }

  doEditUser = async (userID: number, email: string, name: string) => {
    try {
      // stores.ui.setIsLoggingIn(true);
      const requestOptions: RequestInit = {
        method: 'PATCH',
        body: JSON.stringify({
          "patches" : [
            {
              "op" : "replace",
              "path" : "/email",
              "value" : email
            },
            {
              "op" : "replace",
              "path" : "/name",
              "value" : name
            }
          ]
        }),
      };

      const ret = await doFetch(`${baseURL}user/${userID}`, requestOptions);
      // setTimeout(() => {
      //   stores.ui.setIsLoggingIn(false);
      //   // services.nav.dismissAllOverlays();
      // }, 1000);
      console.log('doEditUser OUTPUT', ret);
      return ret;
    } catch (e) {
      // handle error
      console.error('doEditUser error', e);
      // stores.ui.setIsLoggingIn(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] doEditUser`, e);
      return e;
    }
  }

  doEditAccountName = async (accountID: number, name: string) => {
    try {
      // stores.ui.setIsLoggingIn(true);
      const requestOptions: RequestInit = {
        method: 'PATCH',
        body: JSON.stringify({
          "patches" : [
            {
              "op" : "replace",
              "path" : "/name",
              "value" : name
            }
          ]
        }),
      };

      const ret = await doFetch(`${baseURL}account/${accountID}`, requestOptions);
      // setTimeout(() => {
      //   stores.ui.setIsLoggingIn(false);
      //   // services.nav.dismissAllOverlays();
      // }, 1000);
      console.log('doEditAccountName OUTPUT', ret);
      return ret;
    } catch (e) {
      // handle error
      console.error('doEditAccountName error', e);
      // stores.ui.setIsLoggingIn(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] doEditAccountName`, e);
      return e;
    }
  }

  doEditLocationName = async (locationID: number, name: string, timezone: string) => {
    try {
      // stores.ui.setIsLoggingIn(true);
      const requestOptions: RequestInit = {
        method: 'PATCH',
        body: JSON.stringify({
          name, timezone
        }),
      };

      const ret = await doFetch(`${baseURL}location/${locationID}`, requestOptions);
      // setTimeout(() => {
      //   stores.ui.setIsLoggingIn(false);
      //   // services.nav.dismissAllOverlays();
      // }, 1000);
      console.log('doEditLocationName OUTPUT', ret);
      return ret;
    } catch (e) {
      // handle error
      console.error('doEditLocationName error', e);
      // stores.ui.setIsLoggingIn(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] doEditLocationName`, e);
      return e;
    }
  }

  doEditSensorName = async (sensorID: number, name: string) => {
    try {
      // stores.ui.setIsLoggingIn(true);
      const requestOptions: RequestInit = {
        method: 'PATCH',
        body: JSON.stringify({
          name
        }),
      };

      const ret = await doFetch(`${baseURL}sensor/${sensorID}`, requestOptions);
      // setTimeout(() => {
      //   stores.ui.setIsLoggingIn(false);
      //   // services.nav.dismissAllOverlays();
      // }, 1000);
      console.log('doEditSensorName OUTPUT', ret);
      return ret;
    } catch (e) {
      // handle error
      console.error('doEditSensorName error', e);
      // stores.ui.setIsLoggingIn(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] doEditSensorName`, e);
      return e;
    }
  }

  signUp = async (email: string, password: string, name: string, phoneNumber: string):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] signUp`);

    try {
      // stores.ui.setIsLoggingIn(true);

      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({name, phoneNumber, email, phoneRegion: 'US', password}),
      };

      const ret = await doFetch(`${baseURL}user/create`, requestOptions);
      // setTimeout(() => {
      //   stores.ui.setIsLoggingIn(false);
      //   // services.nav.dismissAllOverlays();
      // }, 1000);
      console.log('SIGNUP OUTPUT', ret);
      return ret;
    } catch (e) {
      // handle error
      console.error('signUp error', e);
      stores.ui.setIsLoggingIn(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] signUp`, e);
      return e;
    }
  }

  getBillingInfo = async ():Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] getBillingInfo`);
    if (stores.login.loginObject && stores.login.loginObject.accounts && !stores.ui.isLoggingOut) {
      let selectedAccount = stores.login.selectedAccountID;

      try {
        stores.ui.setIsGettingBilling(true);

        const requestOptions: RequestInit = {
          method: 'GET',
        };

        const ret = await doFetch(`${baseURL}accounts/${selectedAccount}/billing_info`, requestOptions);
        stores.ui.setIsGettingBilling(false);

        if (ret.response.status === 403) {
          console.error(`[CarePenguinAPI] getBillingInfo not logged in`, ret.response);
          stores.ui.setAccount();
          stores.login.doLogout();
          // services.nav.setLoginRoot();
        }

        console.log('ret.result', ret.result);
        const billingInfo = ret.result as BillingInfo;
        console.log('ret.result.sensorsAvailableToInstall', billingInfo.sensorsAvailableToInstall);
        console.log('ret.result.subscriptions.length', billingInfo.subscriptions.length);

        if (billingInfo && billingInfo.sensorsAvailableToInstall && billingInfo.subscriptions.length) {
          stores.ui.setBillingExists(true)
          return true;
        } else {
          stores.ui.setBillingExists(false)
          console.error(`[CarePenguinAPI] getBillingInfo error`, ret.response);
          return false;
        }
      } catch (e) {
        // handle error
        stores.ui.setIsGettingBilling(false);
        // services.nav.dismissAllOverlays();
        console.error(`[CarePenguinAPI] getBillingInfo error`, e);
        return;
      }
    }
  }

  createPortalSession = async (accountID: number) => {
    console.log(`[CarePenguinAPI] createPortalSession`);
    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({accountId: accountID}),
      };

      const ret = await doFetch(`${baseURL}chargebee/portal_session`, requestOptions);
      console.log('createPortal OUTPUT', ret);
      return ret;
    } catch (e) {
      // handle error
      console.error('createPortal error', e);
      console.error(`[CarePenguinAPI] createPortal`, e);
      return e;
    }
  }

  getAccountsList = async (userId: number):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] getAccountsList`);
    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      const ret = await doFetch(`${baseURL}user/${userId}`, requestOptions);
      if (ret.result) {
        console.log('getAccountsList RESULT:', ret.result);
        
        return ret;
      } else {
        console.error('getAccountsList', ret);
      }
    } catch (e) {
      // handle error
      console.error('getAccountsList', e);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] getAccountsList`, e);
      return e;
    }
  }

  getExpectedTimesData = async (locationID: number):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] getExpectedTimesData`);
    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };
      const ret = await doFetch(`${baseURL}location/${locationID}/state_config`, requestOptions);
      if (ret.result) {
        const expectedTimesData = (ret.result as LocationStateConfig).config
        stores.ui.setExpectedTimesData(expectedTimesData);
        return ret;
      } else {
        console.error('getExpectedTimesData', ret);
      }
    } catch (e) {
      console.error('getExpectedTimesData', e);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] getExpectedTimesData`, e);
      return e;
    }
  }

  updateExpectedTimes = async (locationID: number, newUsageConfig: object):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] updateExpectedTimes`);
    try {
      const requestOptions: RequestInit = {
        method: 'PUT',
        body: JSON.stringify({config : newUsageConfig})
      };
      const ret = await doFetch(`${baseURL}location/${locationID}/state_config`, requestOptions);
      return ret;
    } catch (e) {
      console.error('updateExpectedTimes', e);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] updateExpectedTimes`, e);
      return e;
    }
  }

  getSensorData = async (locationID:number):Promise<CPFetchResult | void> => {
    console.log(`[CarePenguinAPI] getSensorData`);
    console.log('getSensorData:', stores.login.loginObject);
    if (!stores.login.loginObject) {
      return;
    }
    try {
      stores.ui.setIsGettingAccount(true);

      const requestOptions: RequestInit = {
        method: 'GET',
      };

      const ret = await doFetch(`${baseURL}location/${locationID}/data?=useCumulativeEvents=false&downsample=5.minute`, requestOptions, false);
      stores.ui.setIsGettingAccount(false);

      if (ret.response.status === 403) {
        console.error('getSensorData', 'User not logged in');
        stores.ui.setAccount();
        stores.login.doLogout();
        // services.nav.setLoginRoot();
      }

      if (ret.result) {
        console.log('SENSOR DATA', ret.result);
        
        return ret;
      } else {
        console.error('getSensorData', ret);
      }
    } catch (e:unknown) {
      // handle error
      console.error('getSensorData', e);
      stores.ui.setIsGettingAccount(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] getSensorData`, e);
      throw e;
    }
  }

  getSensorEvents = async (sensorId: number, locationId:number):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] getSensorEvents`);
    // getSensorEvents = async () => {
    try {
      // stores.ui.setIsGettingAccount(true);

      const requestOptions: RequestInit = {
        method: 'GET',
      };

      const ret = await doFetch(`${baseURL}/location/${locationId}/events?sensor=${sensorId}`, requestOptions, false);
      // stores.ui.setIsGettingAccount(false);
      if (ret.response.status === 403) {
        console.error('getSensorEvents', 'User not logged in');
        stores.ui.setAccount();
        stores.login.doLogout();
        // services.nav.setLoginRoot();
      }
      // console.log('SENSOR EVENTS', Object.keys(ret), ret.result.events);
      
      if (ret.result && (ret.result as SensorEventResponse).events) {
        // stores.ui.setSensorEvents(ret.result.events)
        return ret;
      } else {
        console.error('getSensorData', ret);
      }
    } catch (e) {
      // handle error
      console.error('getSensorData', e);
      stores.ui.setIsGettingAccount(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] getSensorEvents`, e);
      return e;
    }
  }

  getUser = async (userId: any):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] getUser`);
    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };
      const ret = await doFetch(`${baseURL}user/${userId}`, requestOptions);
      // stores.ui.setMemberList(ret.result.members)
      // stores.ui.setInvitesList(ret.result.invites)
      console.log('GET USE RET', ret.result);
      // stores.login.setLoginObject(ret.result as Login)
      return ret;
    } catch (e) {
      // handle error
      console.error('getUser', e);
      console.error(`[CarePenguinAPI] getUser`, e);
      return e;
    }
  }

  deleteUser = async (userId: any):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] deleteUser`);
    try {
      const requestOptions: RequestInit = {
        method: 'DELETE',
      };
      const ret = await doFetch(`${baseURL}user/${userId}`, requestOptions);
      console.log('GET USE RET', ret.result);
      // return this.doLogout();
      return ret;
    } catch (e) {
      // handle error
      console.error('deleteUser', e);
      console.error(`[CarePenguinAPI] deleteUser`, e);
      return e;
    }
  }

  purgeUser = async (phoneNumber: string):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] purgeUser`, phoneNumber);
    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({phoneNumber}),
      };
      const ret = await doFetch(`${baseURL}util/purge_user`, requestOptions);
      console.log('purge user RET', ret.result);
      return ret;
    } catch (e) {
      // handle error
      console.error('purgeUser', e);
      console.error(`[CarePenguinAPI] purgeUser`, e);
      return e;
    }
  }

  doLogin = async (email: string, password: string):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] doLogin`);

    try {
      stores.ui.setIsLoggingIn(true);

      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({email, password}),
      };

      const ret = await doFetch(`${baseURL}login?codeLength=4&returnPhone=true`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsLoggingIn(false);
        // services.nav.dismissAllOverlays();
      }, 1000);
      console.log('LOGIN OUTPUT', ret);
      return ret;
    } catch (e) {
      // handle error
      console.error('doLogin', e);
      stores.ui.setIsLoggingIn(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] doLogin`, e);
      return e;
    }
  }

  doLogout = async ():Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] doLogout`);

    try {
      stores.ui.setIsLoggingOut(true);
      const requestOptions: RequestInit = {
        method: 'POST',
      };

      await doFetch(`${baseURL}logout`, requestOptions, true);
      
      // services.nav.dismissAllOverlays();
      stores.ui.setAccount(undefined);
      stores.login.doLogout();
      // services.nav.setLoginRoot();
      stores.ui.setIsLoggingOut(false);
      return;
    } catch (e) {
      // handle error
      console.error('doLogout', e);
      stores.ui.setIsLoggingIn(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] doLogout error`, e);
      return e;
    }
  }

  doVerify = async (email: string, code: string):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] doVerify`);
    try {
      stores.ui.setIsLoggingIn(true);

      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({email, code}),
      };

      const ret = await doFetch(`${baseURL}login/verify`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsLoggingIn(false);
        // services.nav.dismissAllOverlays();
      }, 1000);

      return ret;
    } catch (e) {
      // handle error
      console.error('doVerify', e);
      stores.ui.setIsLoggingIn(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] doVerify`, e);
      return e;
    }
  }

  doSignUpVerify = async (email: string, code: string):Promise<CPFetchResult | unknown | undefined> => {
    console.log('DOSIGNUPVERIFY', email, code, JSON.stringify({email, code}));
    
    console.log(`[CarePenguinAPI] doSignUpVerify`);
    try {
      stores.ui.setIsLoggingIn(true);

      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({email, code}),
      };

      const ret = await doFetch(`${baseURL}user/verify`, requestOptions);
      console.log('doSignUpVerify RET', ret);

      return ret;
    } catch (e) {
      // handle error
      console.error('doSignUpVerify', e);
      // stores.ui.setIsLoggingIn(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] doSignUpVerify`, e);
      return e;
    }
  }

  doCreateUser = async ():Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] doCreateUser`);
    try {
      // stores.ui.setIsLoggingIn(true);
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({
          name: stores.ui.acceptInviteFirstName + ' ' + stores.ui.acceptInviteLastName, 
          email: stores.ui.acceptInviteEmail,
          password: stores.ui.acceptInvitePass,
          inviteCode: stores.ui.acceptInviteCode,
        }),
      };

      const ret = await doFetch(`${baseURL}user`, requestOptions);
      console.log('CREATE USER RET:', ret);
      return ret;
    } catch (e) {
      // handle error
      // stores.ui.setIsLoggingIn(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] doCreateUser`, e);
      return e;
    }
  }

  doEditAccountInfo = async (userID?: number, email?: string, name?: string):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] doEditAccountInfo`);
    try {
      const requestOptions: RequestInit = {
        method: 'PATCH',
        body: JSON.stringify({
          patches: [
            {
              op: 'replace',
              path: '/email',
              value: email
            },
            {
              op: 'replace',
              path: '/name',
              value: name
            },
          ]
        }),
      };

      const ret = await doFetch(`${baseURL}user/${userID}`, requestOptions, false, true);
      console.log('DO EDIT RET', ret);
      
      return ret;
    } catch (e) {
      // handle error
      console.error(`[CarePenguinAPI] doEditAccountInfo`, e);
      return e;
    }
  }

  // doEditAccountInfo

  sendResetPasswordCode = async (email: string):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] sendResetPasswordCode`);
    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({email}),
      };
      const ret = await doFetch(`${baseURL}reset_password/send_code`, requestOptions);
      console.log('RET //////', ret);
      if (ret.result && (ret.result as ErrorResult).errors) {
        return false;
      }
      return true;
    } catch (e) {
      // handle error
      console.error('sendResetPassCode error', e);
      console.error(`[CarePenguinAPI] sendResetPasswordCode`, e);
      // services.nav.dismissAllOverlays();
      return e;
    }
  }

  doResetPassword = async (email: string, code: number, newPassword: string):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] doResetPassword`);
    
    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({
          email, 
          oneTimeCode: code, 
          newPassword
        }),
      };
      const ret = await doFetch(`${baseURL}/reset_password`, requestOptions);
      // if (ret.result && ret.result.errors) {
      //   return false;
      // }
      console.log('RESET PASS RET', ret);
      
      return ret;
    } catch (e) {
      // handle error
      console.error('sendResetPassCode error', e);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] doResetPassword`, e);
      return e;
    }
  }

  changePassword = async (currentPassword: string, newPassword: string):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] doResetPassword`);
    
    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({
          currentPassword, 
          newPassword
        }),
      };
      const ret = await doFetch(`${baseURL}/change_password`, requestOptions);
      // if (ret.result && ret.result.errors) {
      //   return false;
      // }
      console.log('change PASS RET', ret);
      
      return ret;
    } catch (e) {
      // handle error
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] changePassword`, e);
      return e;
    }
  }

  doAddLocation = async (name:string, timezone: string, lat:number, long:number):Promise<AddLocationResult> => {
    console.log(`[CarePenguinAPI] doAddLocation`);
    const selectedAccount = stores.login.loginObject!.accounts?.find(account => account.id === stores.login.selectedAccountID)
    
    try {
      stores.ui.setIsAPIProcessing(true);

      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({
          id: 0,
          timezone,
          lat,
          long,
          name,
          address: '',
        }),
      };

      const ret = await doFetch(`${baseURL}account/${selectedAccount!.id}/location`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsAPIProcessing(false);
        // services.nav.dismissAllOverlays();
      }, 1000);

      return ret as AddLocationResult;
    } catch (e) {
      // handle error
      console.error('doAddLocation', e);
      stores.ui.setIsAPIProcessing(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] doAddLocation`, e);
      throw e;
    }
  }

  doAddSensor = async (locationId:number, sensorName: string, sensorParticleId: string):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] doAddSensor`);
    
    try {
      stores.ui.setIsAPIProcessing(true);

      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({
          name: sensorName,
          coreId: sensorParticleId,
        }),
      };

      const ret = await doFetch(`${baseURL}location/${locationId}/sensor`, requestOptions, false);
      setTimeout(() => {
        stores.ui.setIsAPIProcessing(false);
        // services.nav.dismissAllOverlays();
      }, 1000);

      return ret;
    } catch (e) {
      // handle error
      console.error('doAddSensor', e);
      stores.ui.setIsAPIProcessing(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] doAddSensor`, e);
      return e;
    }
  }

  getMembershipData = async (accountId: number):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] getMembershipData`);
    stores.ui.setMemberList([]);
    stores.ui.setInvitesList([]);
    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };
      const ret = await doFetch(`${baseURL}accounts/${accountId}/membership`, requestOptions);
      console.log('CAREGIVER RET', ret.result);
      
      stores.ui.setMemberList((ret.result as MembershipDataResult).members)
      stores.ui.setInvitesList((ret.result as MembershipDataResult).invites)
      return ret;
    } catch (e) {
      // handle error
      console.error('getMembershipData', e);
      console.error(`[CarePenguinAPI] getMembershipData`, e);
      return e;
    }
  }

  revokeInvitation = async (accountId: number, inviteId: number ):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] revokeInvitation`);
    try {
      const requestOptions: RequestInit = {
        method: 'DELETE',
      };

      return await doFetch(`${baseURL}accounts/${(accountId)}/invitations/${(inviteId)}`, requestOptions);
    } catch (e) {
      // handle error
      console.error('revoking invitation error', e);
      console.error(`[CarePenguinAPI] revokeInvitation`, e);
      return e;
    }
  }

  removeUser = async (accountId: number, userId: number ):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] removeUser`);
    try {
      const requestOptions: RequestInit = {
        method: 'DELETE',
      };
      const ret = await doFetch(`${baseURL}accounts/${(accountId)}/membership/${(userId)}`, requestOptions);
      // console.log('remove user RET', ret);
      
      return ret;
    } catch (e) {
      // handle error
      console.error('error removing user', e);
      console.error(`[CarePenguinAPI] removeUser`, e);
      return e;
    }
  }

  sendInvitation = async (accountId: number, phoneNumber: string, phoneRegion: string):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] sendInvitation`);
    try {
      const requestOptions: RequestInit = {
        method: 'PUT',
        body: JSON.stringify({phoneNumber, phoneRegion})
      };

      const ret = await doFetch(`${baseURL}accounts/${accountId}/invitations`, requestOptions);
      console.log('send invite ret', ret);
      
      return ret;
    } catch (e) {
      // handle error
      console.error('do invite error !!!!!:', e);
      console.error(`[CarePenguinAPI] sendInvitation`, e);
      return e;
    }
  }

  getNotifications = async (userId: number, accountId: number):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] getNotifications`);
    let toSecond = Math.round((new Date().getTime()) / 1000);
    let fromSecond = Math.round(toSecond - (86400*7));
    let limit = 100;

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      const ret = await doFetch(`${baseURL}user/${userId}/notifications?fromSecond=${fromSecond}&toSecond=${toSecond}&accountId=${accountId}&limit=${limit}`, requestOptions);
      stores.ui.setNotificationsList((ret.result as NotificationsResult).notifications);
      return ret;
    } catch (e) {
      // handle error
      console.error('do getNotifications error !!!!!:', e);
      console.error(`[CarePenguinAPI] getNotifications`, e);
      return e;
    }
  }

  updateLocation = async (locationId: number, locationName: string, locationTimezone: string):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] updateLocation`);
    try {
      stores.ui.setIsAPIProcessing(true);

      const requestOptions: RequestInit = {
        method: 'PATCH',
        body: JSON.stringify({
          name: locationName,
          timezone: locationTimezone,
        }),
      };

      const ret = await doFetch(`${baseURL}location/${locationId}`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsAPIProcessing(false);
        // services.nav.dismissAllOverlays();
        this.getAccount();
        stores.ui.toggleEditLocationModal(false);
        stores.ui.setLocationName('');
        stores.ui.setEditLocationBtnDisabled(true);
      }, 1000);

      return ret;
    } catch (e) {
      // handle error
      console.error('updateLocation Error:', e);
      stores.ui.setIsAPIProcessing(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] updateLocation`, e);
      return e;
    }
  }

  pauseActivityChecks = async (locationId: number):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] pauseActivityChecks`);
    try {
      stores.ui.setIsAPIProcessing(true);
      const requestOptions: RequestInit = {
        method: 'POST',
      };

      const ret = await doFetch(`${baseURL}location/${locationId}/state/pause`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsAPIProcessing(false);
        // services.nav.dismissAllOverlays();
        this.getAccount();
      }, 1000);
      return ret;
    } catch (e) {
      // handle error
      console.error('pause activity checks error:', e);
      stores.ui.setIsAPIProcessing(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] pauseActivityChecks`, e);
      return e;
    }
  }

  unpauseActivityChecks = async (locationId: number):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] unpauseActivityChecks`);
    try {
      stores.ui.setIsAPIProcessing(true);
      const requestOptions: RequestInit = {
        method: 'POST',
      };

      const ret = await doFetch(`${baseURL}location/${locationId}/state/unpause`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsAPIProcessing(false);
        // services.nav.dismissAllOverlays();
        this.getAccount();
      }, 1000);
      return ret;
    } catch (e) {
      // handle error
      console.error('pause activity checks error:', e);
      stores.ui.setIsAPIProcessing(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] unpauseActivityChecks`, e);
      return e;
    }
  }

  snoozeActivityZone = async (locationId: number):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] snoozeActivityZone`);
    try {
      stores.ui.setIsAPIProcessing(true);
      const requestOptions: RequestInit = {
        method: 'POST',
      };

      await doFetch(`${baseURL}location/${locationId}/state/suppress`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsAPIProcessing(false);
        // services.nav.dismissAllOverlays();
        this.getAccount();
      }, 1000);
    } catch (e) {
      // handle error
      stores.ui.setIsAPIProcessing(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] snoozeActivityZone`, e);
      return e;
    }
  }

  deleteLocation = async (locationId: number):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] deleteLocation`);
    try {
      stores.ui.setIsAPIProcessing(true);
      const requestOptions: RequestInit = {
        method: 'DELETE',
      };
      await doFetch(`${baseURL}location/${locationId}`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsAPIProcessing(false);
        // services.nav.dismissAllOverlays();
        this.getAccount();
      }, 1000);
    } catch (e) {
      // handle error
      console.error('delete location error:', e);
      stores.ui.setIsAPIProcessing(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] deleteLocation`, e);
      return e;
    }
  }

  deleteSensor = async (sensorId: number):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] deleteSensor`);
    try {
      stores.ui.setIsAPIProcessing(true);
      const requestOptions: RequestInit = {
        method: 'DELETE',
      };
      await doFetch(`${baseURL}sensor/${sensorId}`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsAPIProcessing(false);
        // services.nav.dismissAllOverlays();
        // startApp()
      }, 1000);
    } catch (e) {
      // handle error
      console.error('delete sensor error:', e);
      stores.ui.setIsAPIProcessing(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] deleteSensor`, e);
      return e;
    }
  }

  updateSensor = async (sensorName: string, sensorId: number):Promise<CPFetchResult | unknown | undefined> => {
    console.log(`[CarePenguinAPI] updateSensor`);
    try {
      stores.ui.setIsAPIProcessing(true);

      const requestOptions: RequestInit = {
        method: 'PATCH',
        body: JSON.stringify({
          name: sensorName,
        }),
      };

      const ret = await doFetch(`${baseURL}sensor/${sensorId}`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsAPIProcessing(false);
        // services.nav.dismissAllOverlays();
        stores.ui.setSensorName('');
        stores.ui.setEditSensorBtnDisabled(true);
        stores.ui.toggleEditSensorModal(false);
        // startApp()
      }, 1000);

      return ret;
    } catch (e) {
      // handle error
      console.error('updateSensor Error:', e);
      stores.ui.setIsAPIProcessing(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] updateSensor`, e);
      return e;
    }
  }

  getAccountsForBrowse = async (from: number, to: number) => {
    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };
  
      const ret = await doFetch(`${baseURL}util/v2/accounts?from=${from}&to=${to}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('getAccountsForBrowse error', e);
      // stores.ui.setIsLoggingIn(false);
      // services.nav.dismissAllOverlays();
      console.error(`[CarePenguinAPI] getAccountsForBrowse`, e);
      return e;
    }

  }


}

export default new CarePenguinService();