import React, { useEffect } from "react";
import * as PropTypes from "prop-types";

import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory } from "react-router-dom";
import headerBg from "../assets/header-bg.png";
import { useStores } from "src/stores";
import carePenguin from "src/services/carePenguin";
import cpLogo from "../assets/images/CarePenguin_logo.png";

const Header = (props) => {
  const { onButtonClick } = props;
  const localizationService = useLocalization();
  // const [selectedAccount, setSelectedAccount] = useState({});
  // const [accountList, setAccountList] = useState([]);
  const { login } = useStores();
  const history = useHistory();

  useEffect(() => {
    if (!login.loginObject) {
      console.log('[not logged in')
      carePenguin.doLogout();
      return;
    }
  }, [login.loginObject, login.loginObject?.accounts, login.selectedAccountID]);


  return (
    <header className="header" style={{ backgroundImage: `url(${headerBg})` }}>
      <div className="nav-container">
        <div className="menu-button">
          <span className={"k-icon k-i-menu"} onClick={onButtonClick} />
        </div>

        <div className="title" onClick={() => history.push('/')} style={{ cursor: 'pointer'}}>
          <img src={cpLogo} alt="carePenguin logo" style={{ width: 50 }} />
          <h1>
            {localizationService.toLanguageString(
              "custom.carePenguinPortal",
              "Admin"
            )}
          </h1>
        </div>
        {/* <div className="settings">
          {login.loginObject && (
            <DropDownList
              className="dropdown"
              data={accountList}
              textField="name"
              dataItemKey="id"
              defaultValue={selectedAccount}
              value={selectedAccount}
              onChange={async (event) => {
                console.log("changing accounts...", event.target.value.id);
                setSelectedAccount({ name: event.target.value.name, id: `${event.target.value.id}` })
                await carePenguin.getAccount(event.target.value.id);
                history.push('/locations')
              }}
            />
          )}
          &nbsp;
          <Button
            onClick={() => {
              const cbInstance = window.Chargebee.getInstance();
              const cart = cbInstance.getCart();
              const product = cbInstance.initializeProduct(
                "cp-service-annual",
                1
              );
              cart.replaceProduct(product);
              // TODO: what if user is a caregiver only?
              const customer = {
                email: login.loginObject.user.email,
                carePenguinUserId: login.loginObject.user.id,
              }
              cart.setCustomer(customer);
              cart.proceedToCheckout();
            }}
            className="buy-sensor-btn"
          >
            Buy Sensor
          </Button>
        </div> */}
      </div>
    </header>
  );
};

Header.displayName = "Header";
Header.propTypes = {
  page: PropTypes.string,
  onButtonClick: PropTypes.func,
};

export default Header;
