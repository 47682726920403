import React, { useEffect, useState } from "react";
// import { toJS } from "mobx";
// import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory, useParams } from "react-router-dom";
// import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { orderBy } from "@progress/kendo-data-query";
// import { Error } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
// import { Input } from "../components/form/Input";
// import { AppContext } from "../AppContext";
import { useStores } from "../stores";
// import { requiredValidator } from "../validators";
// import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import carePenguin from "src/services/carePenguin";
// import { Loader } from "@progress/kendo-react-indicators";

const User = () => {
  // const { languageId, onLanguageChange, onProfileChange, ...formValues } =
  //   React.useContext(AppContext);
  // const localizationService = useLocalization();
  const { user_id } = useParams<AccountsParams>();
  const history = useHistory();
  const { ui } = useStores();
  // const [editError, setEditError] = useState<string>("");
  const [user, setUser] = useState<any>({});
  const [sort, setSort] = useState<Array<any>>([]);
  const [changeMade, toggleChangeMade] = useState(0);
  const [accounts, setAccounts] = useState<Array<any>>([]);
  // const [showDeleteDialogue, setShowDeleteDialogue] = useState<boolean>(false);
  const [editError, setEditError] = useState<string>("");

  useEffect(() => {
    getAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccounts = async () => {
    const call: any = await carePenguin.getUser(parseInt(user_id));
    if (call.result.errors) {
      setEditError(call.result.errors.join("\n"));
      return;
    }
    if (call.result) {
      setAccounts(call.result.accounts);
      setUser(call.result);
    }
  };

  const update = async (value: any) => {
    const { dataItem } = value;
    const { name, id } = dataItem;
    const call: any = await carePenguin.doEditAccountName(id, name);
    if (call.result.errors) {
      setEditError(call.result.errors.join("\n"));
      setTimeout(() => {
        setEditError("");
      }, 5000);
      return;
    }
    if (call.result) {
      cancel({
        dataItem,
      });
    }
  };

  const enterEdit = (event: any) => {
    const { dataItem } = event;
    const resultsCopy = accounts;
    const foundIndex = accounts.findIndex((item) => item.id === dataItem.id);
    resultsCopy[foundIndex].inEdit = true;
    // grid component is not re-rendering with only setSearchResults, no idea why: hence the weirdness below
    setAccounts([]);
    setAccounts(resultsCopy);
    toggleChangeMade(changeMade + 1);
  };

  const cancel = (event: any) => {
    const { dataItem } = event;
    const resultsCopy = accounts;
    const foundIndex = accounts.findIndex((item) => item.id === dataItem.id);
    resultsCopy[foundIndex].inEdit = false;
    // grid component is not re-rendering with only setaccounts, no idea why: hence the weirdness below
    setAccounts([]);
    setAccounts(resultsCopy);
    toggleChangeMade(changeMade + 1);
  };

  const itemChange = (event: any) => {
    const { dataItem } = event;
    const updatedData = accounts.map((item) =>
      item.id === dataItem.id ? { ...item, [event.field]: event.value } : item
    );
    setAccounts(updatedData);
  };

  const commandCell = (dataItem: any) => {
    const { inEdit } = dataItem.dataItem;
    return inEdit ? (
      <td className="k-command-cell">
        <button
          className="k-button k-grid-save-command"
          onClick={() => update(dataItem)}
        >
          Update
        </button>
        <button
          className="k-button k-grid-cancel-command"
          onClick={() => cancel(dataItem)}
        >
          Cancel
        </button>
      </td>
    ) : (
      <td
        className="k-command-cell"
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <button
          className="k-primary k-button k-grid-edit-command"
          onClick={() => enterEdit(dataItem)}
          style={{ marginBottom: 5, width: "90%" }}
        >
          Edit
        </button>
        <button
          style={{ width: "90%", marginLeft: 0 }}
          className="k-primary k-button k-grid-edit-command"
          onClick={() => {
            const account_id = dataItem.dataItem.id;
            ui.setLastUser(user.id);
            history.push(`/account/${account_id}`);
          }}
        >
          View Account
        </button>
      </td>
    );
  };

  return (
    <div id="Profile" className="profile-page main-content">
      <Button onClick={() => history.push("/")} className="back-btn">
        <span className="k-icon k-i-arrow-chevron-left"></span>Back to Search
      </Button>
      <div className="card-container">
        <h3 className="account-title">User: {user.name}</h3>
        <p style={{ color: "red" }}>{editError}</p>
        <p>Email: {user.email}</p>
        <p>ID: {user.id}</p>
        <p>Verified: {user.verified ? "Yes" : "No"}</p>
        {/* <button
          className="k-primary k-button k-grid-edit-command"
          // onClick={() => enterEdit(dataItem)}
          disabled
        >
          Edit
        </button> */}
      </div>
      <div className="card-container">
        <h3 className="account-title">
          Accounts Associated With User: {user.name}
        </h3>
        <p style={{ color: "red" }}>{editError}</p>
        <p>
          Click on the column headers to toggle sort. You can also click and
          drag the borders between them to resize whole columns for better
          visibility.
        </p>
        <div className="card-component">
          <Grid
            data={orderBy(accounts, sort)}
            sortable
            sort={sort}
            onSortChange={(e) => setSort(e.sort)}
            resizable
            editField={"inEdit"}
            onItemChange={itemChange}
          >
            <Column field="id" title="Account ID" editable={false} />
            <Column field="name" title="Account Name" />
            <Column
              field="accountBillingOverview"
              title="Billing Status"
              editable={false}
            />
            <Column
              field="accountOwnerUserId"
              title="Owner Id"
              editable={false}
            />
            <Column
              field="accountOwnerName"
              title="Owner Name"
              editable={false}
            />
            <Column
              field="accountOwnerEmail"
              title="Owner Email"
              editable={false}
            />
            <Column cell={commandCell} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default User;
